<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 17:19:15
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 01:06:07
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\culture\video\detail.vue
 * @Description: 视频播放
 *
-->
<template>
  <div class="fixed-page-mobile culture-video-detail-mobile">
    <div class="container">
      <div class="left" :key="id">
        <p class="title overflow-ellipsis font-bold">
          {{ detail.title }}
        </p>
        <video
          id="video"
          :src="detail.source"
          controls
          width="100%"
          height="421/75rem"
          :muted="true"
          :autoplay="true"
          style="object-fit: fill"
          :webkit-playsinline="true"
          :x-webkit-airplay="true"
          :playsinline="true"
          x5-video-player-type="h5"
          x5-video-orientation="h5"
          :x5-video-player-fullscreen="true"
          controlsList="nodownload"
          preload="auto"
        ></video>
        <div class="info-line">
          <div class="flex-center">
            <span v-for="tag in detail.tag" :key="tag" class="tag">{{
              tag
            }}</span>
          </div>
          <div class="flex-center">
            <span>已播：{{ detail.hits || "-" }}</span>
            <span style="margin-left: 20px">转发到：</span>
            <div
              class="flex-center share-box"
              :data-clipboard-text="href"
              @click="share"
            >
              <c-image :src="require('@/assets/img/performance/icon-dy.png')" />
              <c-image :src="require('@/assets/img/performance/icon-wx.png')" />
              <c-image :src="require('@/assets/img/performance/icon-wb.png')" />
            </div>
          </div>
        </div>
        <div class="flex-center extra-box">
          <span class="extra-info">发布人：{{ detail.author }}</span>
          <span class="extra-info">发布时间：{{ detail.publishDate }}</span>
        </div>
      </div>
      <div class="right">
        <p class="overflow-ellipsis font-bold">推荐视频</p>
        <div
          v-for="item in rightList"
          :key="item.id"
          class="video-item"
          @click="handleDetail(item)"
        >
          <c-image :src="item.image" :alt="item.title" />
          <p>
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getSysHomeConfigPage } from "@/service/system";
import { getArticleDetail, articleHit } from "@/service/culture";
import Clipboard from "clipboard";

export default {
  data() {
    return {
      dayjs,
      id: this.$route.params.id,
      detail: {},
      rightList: [],
      href: "",
    };
  },

  created() {
    this.getData();
    this.getRight();
    this.href = location.href;
  },
  watch: {
    $route() {
      if (
        this.$route.path.startsWith("/culture/video/detail") &&
        this.$route.params.id !== this.id
      ) {
        this.id = this.$route.params.id;
        this.getData();
      }
    },
  },
  methods: {
    async getRight() {
      const res = await getSysHomeConfigPage({
        pageNo: 1,
        pageSize: 3,
        condition: {
          level1: "videoRight",
        },
      });
      this.rightList = (res.data?.data || []).map((item) => ({
        ...item,
        tag: item.tag ? item.tag.split(",") : [],
      }));
    },
    async getData() {
      try {
        const res = await getArticleDetail(this.id);
        const data = res.data || {};
        data.tag = data.tag ? data.tag.split(",") : [];

        this.detail = data;

        this.click();
      } catch (e) {
        console.log(e);
      }
    },
    // 查看详情
    handleDetail(item) {
      window.open(`#/culture/video/detail/${item.targetId}`);
    },
    // 点击量+1
    click() {
      this.detail.id && articleHit(this.detail.id);
    },
    share() {
      var clipboard = new Clipboard(".share-box");
      clipboard.on("success", () => {
        this.$message({
          title: "成功",
          message: "复制成功",
          type: "success",
          duration: 2000,
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message({
          title: "复制失败",
          message: "该浏览器不支持自动复制",
          type: "error",
          duration: 2000,
        });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-video-detail-mobile {
  p {
    padding: 0;
    margin: 0;
  }
  .font-bold {
    font-weight: bold;
  }
  .container {
    margin: 0 auto 50/75rem;
    box-sizing: border-box;
    width: 100%;
    background: #f8f8fa;
    .share-box {
      cursor: pointer;
    }
    .left {
      margin-bottom: 30/75rem;
      background: #ffffff;
      .title {
        padding: 30/75rem 30/75rem 0;
      }
    }
    .right {
      padding: 30/75rem;
      background: #ffffff;
      box-sizing: border-box;
    }
    #video {
      margin: 30/75rem 0;
      width: 100%;
      height: 421/75rem;
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .info-line {
      padding: 0 30/75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14/75rem;
      font-weight: 400;
      color: #212122;
      .tag {
        font-size: 12/75rem;
        color: #212122;
        background: #f8d39f;
        border-radius: 2/75rem;
        height: 20/75rem;
        line-height: 20/75rem;
        padding: 0 8/75rem;
        margin-right: 12/75rem;
      }
      .img {
        width: 24/75rem;
        height: 24/75rem;
        & + .img {
          margin-left: 10/75rem;
        }
      }
    }
    .extra-box {
      padding: 0 30/75rem 30/75rem;
    }
    .extra-info {
      margin-top: 20/75rem;
      font-size: 14/75rem;
      font-weight: 400;
      color: #909399;
      & + .extra-info {
        margin-left: 60/75rem;
      }
    }
    .video-item {
      margin-top: 30/75rem;
      cursor: pointer;
      .img {
        width: 100%;
        height: 340/75rem;
        margin-bottom: 10/75rem;
      }
      p {
        .text-overflow(2);
      }
    }
  }
}
</style>
